<template>
  <div class="list-wrapper">
    <div class="content-wrapper">
      <div class="h-full">
        <div class="table-wrapper">
          <xz-table
            ref="xzTable"
            :columns="columnsLists"
            :data="data"
            :scroll="{ x: '100%', y: scrollHeight }"
            rowKey="id"
          >
            <template v-if="isHandle && addable" #buttons>
              <a-button v-if="sendRepairSpareAddPermission" type="primary" icon="plus" @click="newBackupEquipment">新增备用机</a-button>
            </template>
            <span slot="statusMsg" slot-scope="_, record">
              <span v-if="record.status" :class="['c_tag', record.status ? `status-${record.status}` : '']">
                {{ record.statusMsg }}
              </span>
              <el-tooltip
                v-if="record.status === 8"
                effect="dark"
                :content="`关闭原因：${record.closeReason}`"
                placement="top-start"
              >
                <i :class="['el-icon-warning-outline', record.status ? `status-${record.status}` : '']"></i>
              </el-tooltip>
            </span>
            <span slot="code" slot-scope="text, record">
              <span :class="[sendRepairSpareDetailPermission ? 'code' : '']" @click="() => handleView(record)">{{ text }}</span>
            </span>
            <div slot="action" slot-scope="_, record">
              <a v-if="sendRepairSpareDetailPermission" @click="() => handleAction(record, 'view')">查看</a>
              <a v-if="sendRepairSpareClosePermission && record.status === 2 && record.source === 1" style="margin-right: 8px" @click="() => handleAction(record, 'close')">
                关闭
              </a>
              <a v-if="sendRepairSpareSendBackSignPermission && record.status === 4" style="margin-right: 8px" @click="() => handleAction(record, 'signFor')">
                签收
              </a>
            </div>
          </xz-table>
        </div>
      </div>
    </div>
    <div class="pagination-wrapper">
      <a-pagination
        :current="page.pageNum"
        :total="total"
        :show-total="total => `共 ${total} 条`"
        show-quick-jumper
        @change="handleNumChange"
      />
    </div>
    <DetailDialog
      v-if="dialogVisible"
      ref="DetailDialogRef"
      :dialog-visible.sync="dialogVisible"
      :id="currentRow.id"
    ></DetailDialog>
    <SignForDialog ref="signForDialogRef" :type="2" @callback="handleCallback"></SignForDialog>
    <SendbackDialog ref="sendbackDialogRef" :type="2" @callback="handleCallback"></SendbackDialog>
    <el-dialog title="确定关闭" :visible.sync="closeDialogVisible" width="600px">
      <el-form ref="closeForm" :model="closeForm">
        <el-form-item
          label="关闭原因"
          prop="closeReason"
          label-width="6em"
          :rules="[{ required: true, message: '关闭不能为空', trigger: 'blur' }]"
        >
          <el-input
            v-model="closeForm.closeReason"
            type="textarea"
            :rows="3"
            placeholder="请输入关闭原因"
            :maxlength="200"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { sendRepairPageList, sendRepairSpareClose } from '@/api/logistics.js';
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import DetailDialog from '@/views/Logistics/backupEquipment/components/detailDialog.vue';
import SignForDialog from '@/views/Logistics/components/signForDialog.vue';
import SendbackDialog from '@/views/Logistics/components/sendbackDialog.vue';
import mixin from '../../../mixin';

const COMMON_COLUMNS = [
  {
    title: '备用机租用单',
    dataIndex: 'code',
    key: 'code',
    width: 160,
    ellipsis: true,
    scopedSlots: { customRender: 'code' },
  },
  {
    title: '备用机状态',
    dataIndex: 'statusMsg',
    key: 'statusMsg',
    width: 140,
    ellipsis: true,
    scopedSlots: { customRender: 'statusMsg' },
  },
  {
    title: '用户名称',
    dataIndex: 'userParty',
    key: 'userParty',
    width: 120,
    ellipsis: true,
    customRender: text => {
      return text ? text : '--';
    },
  },
  {
    title: '创建人',
    dataIndex: 'createUserName',
    key: 'createUserName',
    width: 120,
    ellipsis: true,
    customRender: text => {
      return text ? text : '--';
    },
  },
  {
    title: '联系方式',
    dataIndex: 'createUserPhone',
    key: 'createUserPhone',
    width: 160,
    ellipsis: true,
    customRender: text => {
      return text ? text : '--';
    },
  },
];

export default {
  mixins: [mixin],
  name: 'LV_SEND_FOR_REPAIR',
  components: {
    xzTable,
    DetailDialog,
    SignForDialog,
    SendbackDialog,
  },
  props: {
    inquirePriceCode: {
      type: [String, Number],
      default: '',
    },
    inquirePriceId: {
      type: [String, Number],
      default: '',
    },
    isHandle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    columnsLists () {
      if (this.isHandle) {
        return [
          ...COMMON_COLUMNS,
          {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: 100,
            scopedSlots: { customRender: 'action' },
          },
        ];
      } else {
        return COMMON_COLUMNS;
      }
    },
    addable () {
      // status 8-> 已关闭， 5-> 已寄回已签收
      return this.data.length === 0 || this.data.every(item => item.status === 8 || item.status === 5);
    },
  },
  inject: {
    closeDialog: {
      default: undefined,
    },
  },
  data () {
    return {
      data: [],
      total: 0,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      page: {
        pageSize: 10,
        pageNum: 1,
      },
      scrollHeight: 408,
      dialogVisible: false,
      currentRow: {},
      closeDialogVisible: false,
      closeForm: {
        id: undefined,
        closeReason: '',
        operatorName: '',
      },
      userInfo: {},
    };
  },
  methods: {
    handleView (record) {
      if (this.sendRepairSpareDetailPermission) {
        if (this.isHandle) {
          this.currentRow = record;
          this.dialogVisible = true;
        } else {
          this.closeDialog && this.closeDialog();

          this.$router.push({
            name: 'LOGISTICS_BACKUP_EQUIPMENT_DETAIL',
            query: {
              id: record.id,
            },
          });
        }
      }
    },
    handleNumChange (num) {
      this.page.pageNum = num;

      this.handleSearch();
    },
    async handleSearch () {
      this.data = [];
      const params = {
        type: '2', // 备用机
        inquirePriceCode: this.inquirePriceCode,
        ...this.page,
        orderBy: [
          {
            orderByProperty: 'createTime',
            orderByType: 'DESC',
          },
        ],
      };

      const { body } = await sendRepairPageList(params);

      this.data = body?.list || [];

      this.total = (body?.total || 0) * 1;
    },
    handleAction (record, action) {
      if (action === 'close') {
        this.closeForm.id = record.id;
        this.closeForm.operatorName = this.userInfo.name;
        this.closeForm.closeReason = '';
        this.closeDialogVisible = true;

        this.$nextTick(() => {
          this.$refs.closeForm.resetFileds();
        });
      } else if (action === 'signFor') {
        this.$refs.signForDialogRef.init(record.id);
      } else if (action === 'view') {
        this.$router.push({
          name: 'LOGISTICS_BACKUP_EQUIPMENT_DETAIL',
          query: {
            id: record.id,
          },
        });
      }
    },
    handleCallback () {
      this.handleSearch();
    },
    handleClose () {
      this.$refs.closeForm.validate(async valid => {
        if (valid) {
          await sendRepairSpareClose(this.closeForm);

          this.$message.success('关闭成功');
          this.handleSearch();

          this.closeDialogVisible = false;
        }
      });
    },
    newBackupEquipment () {
      this.$refs.sendbackDialogRef.init(this.inquirePriceId);
    },
  },
  created () {
    this.userInfo = this.$local.get('userInfo') ? JSON.parse(this.$local.get('userInfo')) : {};
  },
  mounted () {
    this.handleSearch();
  },
};
</script>
<style scoped lang="scss">
@mixin tag_round() {
  padding: 4px 8px;
  border-radius: 4px;
}

.list-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content-wrapper {
    flex: 1;
    overflow: hidden;
    padding: 0 0 8px;

    .h-full {
      height: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;

      .table-wrapper {
        padding: 8px 12px;
        flex: 1;
        overflow: auto;

        .ant-radio-button-wrapper:not(:first-child)::before {
          width: 0;
        }

        .c_tag {
          @include tag_round();
          margin-right: 0.2em;
        }

        .status-2,
        .status-3 {
          background-color: #fefae0;
          color: #fab007;

          &:before {
            color: #fab007;
          }
        }

        .status-4,
        .status-5 {
          background: #e2f3fe;
          color: #237ffa;

          &:before {
            color: #237ffa;
          }
        }

        .status-8 {
          background-color: #f2f4f7;
          color: #1f2733;

          &:before {
            color: #1f2733;
          }
        }

        .code {
          color: #237ffa;
          cursor: pointer;
        }
      }
    }
  }

  .pagination-wrapper {
    padding: 0 16px;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

/deep/.ant-table-body::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

/deep/ .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 5px #c9ced6;
  background: #c9ced6;
}

//鼠标移入样式
/deep/ .ant-table-body::-webkit-scrollbar-thumb:hover {
  background: #c9ced6;
}
</style>
